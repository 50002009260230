
import React,{useState} from 'react';
import { Link as LinkScroll} from 'react-scroll';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import './navbar.css';

 
 import {Logo2} from "../../components"
 

const Navbar = () => {

  


  const [navbar, setNavbar] = useState(false);
  const changeBackground = ()=>{

    if(window.scrollY >= 0.01){
      setNavbar(true)
    }
    else{
      setNavbar(false)
    }
  }
  window.addEventListener('scroll',changeBackground);


  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);


  return (
   <header id="nav-bar" >
         <nav className={ navbar ? 
        'navbar navbar-expand-lg navbar-light active' : 'navbar navbar-expand-lg navbar-light'}>
    <div className="container-fluid">
    
     <Logo2 /> 
     <button  className="navbar-toggler">
     
     
        
         <div className='menu-icon-new' onClick={handleClick}>

            {click
          ? <RiCloseLine color="#212529" size={27} />
          : <RiMenu3Line color="#212529" size={27}  /> }

          </div>
            <ul className={click ? 'nav-menu-new active' : 'nav-menu-new'}>
              
           
            
            <li className='nav-item-new'>
              <LinkScroll
                to='services'
                offset={-50}
                className='nav-links-new'
                onClick={closeMobileMenu}
              >
                SERVICES
              </LinkScroll>
            </li>
             <li className='nav-item-new'>
              <LinkScroll to='projects' 
              offset={-65}
              className='nav-links-new' 
              onClick={closeMobileMenu}>
                PROJECTS
              </LinkScroll>
            </li>
            
             <li className='nav-item-new'>
              <LinkScroll
                to='about'
                offset={-75}
                className='nav-links-new'
                onClick={closeMobileMenu}
              >
                ABOUT
              </LinkScroll>
            </li>
             <li className='nav-item-new'>
              <LinkScroll
                to='careers'
                offset={-50}
                className='nav-links-new'
                onClick={closeMobileMenu}
              >
                CAREERS
              </LinkScroll>
            </li> 
             <li className='nav-item-new'>
              <LinkScroll
                to='contact'
                offset={-55}
                className='nav-links-new'
                onClick={closeMobileMenu}
              >
                CONTACT
              </LinkScroll>
            </li>
          </ul>
          
      
    </button> 

    <div className= "collapse navbar-collapse" id="navbarNav">
       
      <ul className= "navbar-nav ms-auto">
       
       
        
        <li className="nav-item">
          <LinkScroll className="nav-link"  to="services" offset={-45}  >SERVICES</LinkScroll>
         
        </li>
         <li className="nav-item">
          <LinkScroll className="nav-link"  to="projects" offset={-65} >PROJECTS</LinkScroll>
        </li>
       
        <li className="nav-item">
          <LinkScroll className="nav-link "  to="about" offset={-60}>ABOUT</LinkScroll>
        </li>
         <li className="nav-item">
          <LinkScroll className="nav-link nav-careers"  to="careers" offset={-48} >CAREERS</LinkScroll>
        </li> 
        <li className="nav-item">
          <LinkScroll className="nav-link nav-contact"  to="contact" offset={-58}> CONTACT</LinkScroll>
        </li>
        
      </ul>
     
    </div>
  </div>
</nav>

        </header>
  )
}

export default Navbar
