import React from 'react';

import './logo2.css';


import logo from "../../assets/images/logo2/ponos-logo2.svg";


//  import {Link} from 'react-router-dom';

import { Link as LinkScroll } from 'react-scroll';

const Logo2 = () => {




  return (

    <LinkScroll to='ponos' className="navbar-brand2">

      <span className="nav-logo2-content">
        <span className="nav-logo2-img"><img src={logo} alt="Ponos" /></span>
       
         <span className="nav-logo2-text">PONOS</span> 

      </span>


    </LinkScroll>




  )
}

export default Logo2
