import React from 'react'

import './partnersSingle.css';


import telos1 from "../../assets/images/partners/telos-brand.svg"
import polygon1 from "../../assets/images/partners/polygon-brand.svg"
  import ethernal1 from "../../assets/images/partners/ethernalNew.svg"
  import cryptovalley from "../../assets/images/member/cva.svg"
  import ethereumfoundation from "../../assets/images/partners/ethereum-foundation-2.svg"


const PartnersSingle = () => {

  

  return (
    <div className='p-single'>
             <h4>Our Partners</h4>
            
        
        <div className='p-single-content p-single-content-up'>

          
              <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer" className='p-single-filter-main p-single-img-relative a-first'>
             

                   <span>
              
                <img 
               
                 src={polygon1} 
                 alt="polygon"
                 className={"p-single-filter-img-polygon"}
            
                  />    
             </span>

                 


         </a>

           <a href="https://www.telos.net/" target="_blank" rel="noopener noreferrer" className='p-single-filter-main p-single-img-relative a-first-new'>
             

                   <span>
              
                <img 
               
                 src={telos1} 
                 alt="telos"
                 className={"p-single-filter-img-telos"}
            
                  />    
             </span>

                 


         </a>
         

        </div>

         <div className='p-single-content p-single-content-up'>


          <a href="https://ethernal.tech/" target="_blank" rel="noopener noreferrer" className='p-single-filter-main 
          p-single-img-relative a-first'>
               <span>
              
                <img 
               
                 src={ethernal1} 
                 alt="ethernal"
                    className={"p-single-filter-img"}
               
                  />    
             </span>

                 
         </a>

          <a href="https://cryptovalley.swiss/" target="_blank" rel="noopener noreferrer" className='p-single-filter-main p-single-img-relative'>
               <span>
              
                <img 
               
                 src={cryptovalley} 
                 alt="cryptovalley"
                    className={"p-single-filter-img-cva"}
               
                  />    
             </span>

                 
         </a>



        </div>

         <div className='p-single-content'>


          <a href="https://ethereum.foundation/" target="_blank" rel="noopener noreferrer" className='p-single-filter-main 
          p-single-img-relative a-first'>
               <span>
              
                <img 
               
                 src={ethereumfoundation} 
                 alt="ethereumfoundation"
                    className={"p-single-filter-img-ef"}
               
                  />    
             </span>

                 
         </a>


        </div>
                     
                    
                </div>
  )
}

export default PartnersSingle
