import React from 'react';

import './team.css';
import team1 from "../../assets/images/team/Slobodan_Lukovic.jpg"
import team2 from "../../assets/images/team/Milos_Stanisavljevic.jpg"
import team3 from "../../assets/images/team/Farnam_Khalili_Maybodi.jpg"
import team4 from "../../assets/images/team/Vladimir_Petrovic.jpg"
import team5 from "../../assets/images/team/Lorenzo_Grassi.jpg"
import team6 from "../../assets/images/team/Ahmet_Can_Mert.jpg"
import team7 from "../../assets/images/team/Andres_Cardona.jpg"
import team8 from "../../assets/images/team/Thomas_Troeger.jpg"
import team9 from "../../assets/images/team/Rinse_Wester.jpg"
import team10 from "../../assets/images/team/Dragan_Lambic.jpg"
 import team11 from "../../assets/images/team/Zarko_Gvozdenovic.png"


const Team = () => {
  return (
    <section id='team'>
                <div className="container px-5 team-main">
                   
                    <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                        <div className="col mb-5 mb-5 mb-xl-0">
                            <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team1} alt="Slobodan-Lukovic-PhD" />
                                   
                                <h6 className="fw-bolder team-name">Slobodan Lukovic, PhD</h6>
                                <div className="team-position">CEO & Co-founder</div>
                            </div>
                        </div>
                        <div className="col mb-5 mb-5 mb-xl-0">
                            <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team2} alt="Milos-Stanisavljevic-PhD" />
                                <h6 className="fw-bolder team-name">Milos Stanisavljevic, PhD</h6>
                                <div className="team-position">CTO & Co-founder</div>
                            </div>
                        </div>
                        <div className="col mb-5 mb-5 mb-sm-0">


                             <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team8} alt="Thomas-Troeger" />
                                <h6 className="fw-bolder team-name">Thomas Troeger</h6>
                                <div className="team-position">Senior HW designer - Tech lead</div>
                            </div>


                        </div>
                        <div className="col mb-5">
                            <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team4} alt="Vladimir-Petrovic" />
                                <h6 className="fw-bolder team-name">Vladimir Petrovic</h6>
                                <div className="team-position">Senior HW designer - Tech lead</div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                        <div className="col mb-5 mb-5 mb-xl-0">
                            <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team7} alt="Andres-Cardona-PhD" />
                                <h6 className="fw-bolder team-name">Andres Cardona, PhD</h6>
                                <div className="team-position">Senior HW designer</div>
                            </div>


                        </div>
                        <div className="col mb-5 mb-5 mb-xl-0">
                           
                               <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team3} alt="Farnam-Khalili-Maybodi-PhD" />
                                <h6 className="fw-bolder team-name">Farnam Khalili Maybodi, PhD</h6>
                                <div className="team-position">Senior HW designer</div>
                            </div>


                        </div>
                        
                        <div className="col mb-5 mb-5 mb-sm-0">
                             <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team6} alt="Ahmet-Can-Mert-PhD" />
                                <h6 className="fw-bolder team-name">Ahmet Can Mert, PhD</h6>
                                <div className="team-position">Researcher - HW designer</div>
                            </div>

                            

                        </div>
                        <div className="col mb-5">

                             <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team5} alt="Lorenzo-Grassi-PhD" />
                                <h6 className="fw-bolder team-name">Lorenzo Grassi, PhD</h6>
                                
                                <div className="team-position">Researcher - Cryptography expert</div>
                            </div>



                        </div>
                    </div>



                    


                    <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-start">
                        <div className="col mb-5 mb-5 mb-xl-0">
                            <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team9} 
                                alt="Rinse-Wester-PhD" />
                                <h6 className="fw-bolder team-name">Rinse Wester, PhD</h6>
                                <div className="team-position">Senior HW designer</div>
                            </div>


                        </div>

                        {/* new  ############################### */}
                         <div className="col mb-5 mb-5 mb-xl-0">
                           
                               <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team10} alt="Dragan-Lambic-PhD" />
                                <h6 className="fw-bolder team-name">Dragan Lambic, PhD</h6>
                                <div className="team-position">Researcher - Cryptography expert</div>
                            </div>


                        </div>
                        
                        <div className="col mb-5 mb-5 mb-sm-0">
                             <div className="text-center team-content">
                                <img className="img-fluid mb-4 px-4 team-filter-img team-img" src={team11} 
                                alt="Zarko Gvozdenovic" />
                                <h6 className="fw-bolder team-name">Zarko Gvozdenovic</h6>
                                <div className="team-position">SW Principal Consultant</div>
                            </div>

                            

                        </div>
                       
                    </div>

                  
                </div>
            </section>
  )
}

export default Team
