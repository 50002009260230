import React from 'react'

import './partnersSingleLine.css';


import telos1 from "../../assets/images/partners/telos-brand.svg"
import polygon1 from "../../assets/images/partners/polygon-brand.svg"
  import ethernal1 from "../../assets/images/partners/ethernalNew.svg"
  import cryptovalley from "../../assets/images/member/cva.svg"
  import ethereumFoundation from "../../assets/images/partners/ethereum-foundation-2.svg"


const PartnersSingleLine = () => {

  


  return (
    <div className='pl-single'>

      <h4 className='nowrap'>Our Partners</h4>

        <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer" className='pl-single-filter-main pl-single-img-relative pl-first'>
             

                   <span>
              
                <img 
               
                 src={polygon1} 
                 alt="polygon"
                 className={"pl-single-filter-img-polygon"}
                
                  />    
             </span>

                   

         </a>

       <a href="https://www.telos.net/" target="_blank" rel="noopener noreferrer" className='pl-single-filter-main pl-single-img-relative pl-first-new'>
             

                   <span>
              
                <img 
               
                  src={telos1} 
                 alt="telos"
                 className={"pl-single-filter-img-telos"}
               
                  />    
             </span>

                   

         </a>
     

       <a href="https://ethernal.tech/" target="_blank" rel="noopener noreferrer" className='pl-single-filter-main pl-single-img-relative pl-first'>
               <span>
              
                <img 
               
                 src={ethernal1} 
                 alt="ethernal"
                 className={"pl-single-filter-img"}
               
                  />    
             </span>

                  
         </a>

          <a href="https://cryptovalley.swiss/" target="_blank" rel="noopener noreferrer" className='pl-single-filter-main pl-single-img-relative pl-first2'>
               <span>
              
                <img 
               
                 src={cryptovalley} 
                 alt="cryptovalley"
                 className={"pl-single-filter-img-cva"}
               
                  />    
             </span>

                  
         </a>

          <a href="https://ethereum.foundation/" target="_blank" rel="noopener noreferrer" className='pl-single-filter-main pl-single-img-relative pl-first-new2'>
               <span>
              
                <img 
               
                 src={ethereumFoundation} 
                 alt="ethereumFoundation"
                 className={"pl-single-filter-img-ef"}
               
                  />    
             </span>

                  
         </a>
                    
                </div>
  )
}

export default PartnersSingleLine
